<template>
  <!-- <div id="search-select"> -->
  <div id="search-select" @focusout="showOptions = false">
    <div class="search_select_container">
      <div
        @click="showOptions = !showOptions"
        class="search_select_container__item text_search_container"
      >
        <div class="text_search_container__item select_input">
          <input
            id="search_input"
            v-model="inputText"
            autocomplete="off"
            @input="onInput"
            @keyup.up="onUp"
            @keyup.up.stop
            @keyup.down="onDown"
            @keyup.down.stop
            @keyup.enter="selectOption(filteredOptions[hoverIdex])"
            @keyup.enter.stop
            :placeholder="
              selectedOption !== null ? selectedOption : 'Не выбрано'
            "
            :class="selectedOption !== null ? 'selected_option' : 'Не выбрано'"
          />
        </div>
        <div class="text_search_container__item see_options_button">
          <button @click.stop @click="this.showOptions = !this.showOptions">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              transform="rotate(270)"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                fill="#a1a3ab"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <Transition
        ><div
          v-if="showOptions && filteredOptions.length > 0"
          ref="optionMenu"
          class="search_select_container__item select_container"
        >
          <!-- <div class="default_select_container">
          <div class="default_select_container__item title">
            <label>Default</label>
          </div>
          <div class="default_select_container__item option_button">
            <button
            title="Defalt"
            @click="selectOption(item)"
          >
            <div class="button_text">"Что-то дефолтное"</div>
          </button>
          </div>

         
        </div> -->
          <div
            v-for="(item, index) in filteredOptions"
            :key="index"
            class="select_container__item"
          >
            <button
              :title="item !== null ? item : 'Не выбрано'"
              @mouseover="hoverIdex = index"
              class="option_button"
              :class="[
                item === selectedOption && index !== hoverIdex
                  ? 'selected'
                  : '',
                item === defaultValue &&
                defaultValue !== null &&
                index !== hoverIdex
                  ? 'default'
                  : '',
                index === hoverIdex ? 'hover' : '',
              ]"
              @click="selectOption(item)"
            >
              <div class="button_icon">
                {{ item === selectedOption ? "✓" : "" }}
                {{ item === defaultValue && defaultValue !== null ? "⚙" : "" }}
              </div>
              <div class="button_text">
                {{ item !== null ? item : "Не выбрано" }}
              </div>
            </button>
          </div>
        </div></Transition
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
    },
    selected: {
      default() {
        return null;
      },
    },
    defaultValue: {
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      inputText: null,
      showOptions: false,
      selectedOption: null,
      hoverIdex: 0,
    };
  },
  methods: {
    onInput() {
      this.showOptions = true;
      this.hoverIdex = 0;
    },
    onUp() {
      if (this.hoverIdex > 0) {
        this.hoverIdex -= 1;
      }
      if (!this.showOptions) {
        this.showOptions = true;
      }

      this.$refs.optionMenu.scrollTop = this.$refs.optionMenu.scrollTop - 20;
    },
    onDown() {
      if (this.hoverIdex < this.filteredOptions.length - 1) {
        this.hoverIdex += 1;
      }
      if (!this.showOptions) {
        this.showOptions = true;
      }

      this.$refs.optionMenu.scrollTop = this.$refs.optionMenu.scrollTop + 21;
    },
    selectOption(value) {
      this.showOptions = false;
      this.selectedOption = value;
      this.inputText = null;
      this.$emit("update:selected", value);
      this.$emit("change", value);
    },
  },
  created() {
    window.addEventListener("click", (event) => {
      if (event.target.parentNode && event.target.id !== "search_input") {
        this.inputText = null;
        this.showOptions = false;
      }
    });
    this.selectedOption = this.selected;
  },
  computed: {
    filteredOptions() {
      const tempOptions = this.options.map((el) => (el === null ? "" : el));
      if (
        this.inputText === undefined ||
        this.inputText === null ||
        this.inputText.length === 0
      ) {
        return [null, ...tempOptions];
      }
      return tempOptions.filter((el) =>
        String(el.toLowerCase()).includes(this.inputText.toLowerCase())
      );
    },
  },
  watch: {
    options() {
      this.selectedOption = this.selected;
    },
    selected() {
      this.selectedOption = this.selected;
    },
  },
};
</script>

<style lang="less">
#search-select {
  position: relative;
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  .search_select_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .text_search_container {
      width: inherit;
      display: flex;
      align-items: center;
      padding: 3px 5px;
      border: 1px solid #c9c9c9;
      border-radius: 5px;
      justify-content: space-between;

      .see_options_button {
        button {
          all: unset;
          height: 15px;
          width: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #4151b725;
            border-radius: 5px;
            transition: 0.7s;
          }
        }
      }

      .select_input {
        width: 100%;
        input {
          all: unset;
          height: 20px;
          width: 99%;
          font-size: 0.9em;
          margin-left: 5px;
          color: #817f7f;
        }

        .selected_option {
          &::placeholder {
            color: black;
          }
        }
      }

      .selected_input {
        height: 20px;
        width: 90%;
        font-size: 0.9em;
        margin-left: 5px;
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    .select_container {
      z-index: 1;
      display: flex;
      flex-direction: column;
      position: absolute;
      margin-top: 25px;
      border: 1px solid #c9c9c9;
      background-color: #ffffff;
      border-top: unset;
      overflow-y: scroll;
      max-height: 300px;

      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 5px 5px 3px 5px;
      width: inherit;
      .default_select_container {
        display: flex;
        flex-direction: column;
        border-bottom: 10px solid #c9c9c91c;
        .title {
        }
      }
      .select_container__item {
        margin-left: 1px;
        border-top: 1px solid #c9c9c91c;
        display: flex;
        align-items: center;

        .option_button {
          all: unset;
          width: 100%;
          color: #000000;
          font-size: 0.9em;
          cursor: pointer;
          padding: 2px 0px 2px 5px;
          height: 20px;

          display: flex;
          align-items: center;
          margin: 1px 0px;
          &.hover {
            background-color: #b7419014;
            border-radius: 5px;
            transition: 0.4s;
          }
          &.default {
            background-color: #41b79825;
            border-radius: 5px;
            transition: 0.7s;
          }
          .button_icon {
            width: 20px;
            height: 10px;
            font-size: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .button_text {
            margin-left: 5px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
          }

          // &:hover {
          //   background-color: #4151b714;
          //   border-radius: 5px;
          //   transition: 0.7s;
          // }

          &.selected {
            background-color: #4151b714;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>
